import React from "react";
import "./essentiel.css";

const DEBOURS = () => {
  return (
    <>
      <div className="debours-container">
        <h4>Les débours</h4>
        <p>
          Les débours sont définis par l’article R 444-2, 7° du Code de commerce, comme les sommes avancées pour le
          compte du client par le notaire pour la réalisation d’une prestation.
          <br />
          <br /> Ceux, dont il est possible d’être remboursé, sont visés à l’article annexe 4-8, 6° b) du Code de
          commerce. Il s’agit de toutes sommes dues à des tiers et payées par le notaire pour le compte de son client à
          l’occasion d’une prestation listée à l’article annexe 4-7.
        </p>

        <h5>Dans la pratique, sont quotidiennement rencontrés :</h5>

        <div className="debours-section">
          <table>
            <thead>
              <tr>
                <th colSpan={2}>Les demandes de pièces d'état-civil réalisées auprès de COMEDEC</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={2}>
                  Les débours, facturés par l’ADSN, sont, depuis le 1er juillet 2024, d’un montant de :
                </td>
              </tr>
              <tr>
                <td className="debours-section-td" style={{ textAlign: "center" }}>
                  0,64 € TTC
                </td>
                <td>
                  par demande avec réponse positive pour les pièces d’état civil des personnes nées à l’étranger,
                  délivrées par le service central d’état civil de Nantes
                </td>
              </tr>
              <tr>
                <td className="debours-section-td" style={{ textAlign: "center" }}>
                  3,64 € TTC
                </td>
                <td>
                  (dont 3 € TTC de coût de vérification) par demande avec réponse positive auprès des mairies adhérentes
                  au dispositif pour les personnes nées sur le territoire français.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="debours-section">
          <table>
            <thead>
              <tr>
                <th>Les demandes de documents auprès du greffe du tribunal de commerce</th>
              </tr>
            </thead>
            <tbody className="debours-tbody">
              <tr>
                <td>L’extrait Kbis</td>
              </tr>
              <tr>
                <td>L’état complet d’endettement</td>
              </tr>
              <tr>
                <td>Le résultat de recherche en matière de procédure collective</td>
              </tr>
              <tr>
                <td>Les derniers statuts mis à jour</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h5>Plus spécifiquement et en plus de ceux ci-dessus énoncés, peuvent faire l’objet de débours :</h5>

        <div className="debours-section">
          <table>
            <thead>
              <tr>
                <th colSpan={2}>En droit immobilier :</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={2} className="debours-section-td" style={{ textAlign: "center" }}>
                  Dans le cadre d'une vente :
                </td>
                <td>
                  L’obtention des documents relatifs à l’urbanisme, sous réserve de l’accord du client, si son coût est
                  élevé ; *
                </td>
              </tr>
              <tr>
                <td>La réponse rapide sollicitée auprès de la SAFER.</td>
              </tr>
              <tr>
                <td className="debours-section-td" style={{ textAlign: "center" }}>
                  
                  Dans le cadre d’une mainlevée :
                </td>
                <td>L’obtention du pouvoir émanant du créancier afin d’établir l’acte.</td>
              </tr>
            </tbody>
          </table>
          <p className="note">
            <i>
              * Remarque :<br />
              En plus du remboursement de la somme avancée pour le compte du client, des émoluments de formalités seront
              taxés sous réserve du respect de leurs conditions de perception, à l’exception de celui visé au n° 204 du
              Tableau 5 du décret du 26 février 2016.
              <br />
              En effet, l’émolument « Obtention de tout document nécessaire à la rédaction d’un acte et non tarifé par
              ailleurs » est exclu dans l’hypothèse où la pièce sollicitée a été obtenue d’un tiers rémunéré pour cette
              prestation.
            </i>
          </p>
        </div>

        <div className="debours-section">
          <table className="styled-table">
            <thead>
              <tr>
                <th colSpan={3} className="header">
                  En droit de la famille :
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={4} className="debours-section-td" style={{ textAlign: "center", verticalAlign: "middle" }}>
                  Dans le cadre d'une succession :
                </td>
                <td rowSpan={2}>
                  La consultation du fichier des dispositions de dernières volontés. Les débours facturés, par l'ADSN,
                  sont, depuis le 1er juillet 2024, d'un montant de :
                </td>
                <td>
                  <b>12,71 € TTC</b> pour toute réponse négative ou si la disposition a été déposée auprès de l'office
                  notarial chargé du règlement de la succession.
                </td>
              </tr>
              <tr>
                <td>
                  <b>25,42 € TTC</b> pour toute réponse positive et si la disposition a été déposée auprès d'un autre
                  office notarial que celui chargé du règlement de la succession.
                </td>
              </tr>
              <tr>
                <td colSpan={2}>Les honoraires du commissaire-priseur dans l’hypothèse d’un inventaire.</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  La facture de publication auprès d’un journal d’annonces légales dans le cadre de la procédure d’envoi
                  en possession…
                </td>
              </tr>
              <tr>
                <td className="debours-section-td" style={{ textAlign: "center", verticalAlign: "middle" }}>
                  Dans le cadre d'un changement de régime matrimonial :
                </td>
                <td colSpan={2}>La facture de publication auprès d’un journal d’annonces légales.</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="debours-section">
          <table>
            <thead>
              <tr>
                <th>En droit des affaires :</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Les demandes de documents obtenus auprès du greffe du tribunal de commerce</td>
              </tr>
              <tr>
                <td>La facture de publication auprès d’un journal d’annonces légales.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DEBOURS;
