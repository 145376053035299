import { Document, Font, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import * as React from "react";
import fontRobotoRegular from "../../assets/fonts/Roboto/Roboto-Regular.ttf";
import fontRobotoLight from "../../assets/fonts/Roboto/Roboto-Light.ttf";
import fontRobotoBold from "../../assets/fonts/Roboto/Roboto-Bold.ttf";
import fontRobotoMedium from "../../assets/fonts/Roboto/Roboto-Medium.ttf";
import fontRobotoItalic from "../../assets/fonts/Roboto/Roboto-MediumItalic.ttf";

Font.register({ family: "RobotoRegular", src: fontRobotoRegular });
Font.register({ family: "RobotoLight", src: fontRobotoLight });
Font.register({ family: "RobotoMedium", src: fontRobotoMedium });
Font.register({ family: "RobotoBold", src: fontRobotoBold });
Font.register({ family: "RobotoItalic", src: fontRobotoItalic });
Font.registerHyphenationCallback((word) => [word]);

let newDate = new Date();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let day = newDate.getDate();

if (month < 10) {
  month = "0" + month;
}

if (day < 10) {
  day = "0" + day;
}
const currentDate = `${day}/${month}/${year}`;

const styles = StyleSheet.create({
  fontRegular: { fontFamily: "RobotoRegular" },
  fontLight: { fontFamily: "RobotoLight" },
  fontBold: { fontFamily: "RobotoBold" },
  fontMedium: { fontFamily: "RobotoMedium" },
  blockDebours: {
    width: 225,
    maxHeight: 25,
    marginTop: 20,
    marginRight: 0,
    marginLeft: 0,
    flex: 0.5,
    flexDirection: "row",
    border: "1px solid grey",
    borderRadius: 3,
    paddingBottom: 24,
  },
  blockEmoluments: {
    width: 225,
    marginLeft: 0,
    marginRight: 0,
    flexDirection: "column",
    border: "1px solid grey",
    borderRadius: 3,
    minHeight: 60,
    height: "auto",
    maxHeight: 130,
  },
  blockFiscalite: {
    width: 225,
    marginRight: 0,
    marginLeft: 0,
    flex: 0.5,
    flexDirection: "column",
    border: "1px solid grey",
    borderRadius: 3,
    minHeight: 80,
  },
  blockFiscalite2: {
    width: 225,
    marginRight: 0,
    marginLeft: 0,
    flex: 0.5,
    flexDirection: "column",
    border: "1px solid grey",
    borderRadius: 3,
    minHeight: 100,
  },
  blockLeft: {
    position: "relative",
    marginLeft: "10%",
    marginRight: 0,
    width: "100%",
  },
  blockRight: {
    position: "relative",
    marginLeft: 0,
    marginRight: "5%",
    width: "100%",
  },
  bodyCtnRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    alignContent: "space-between",
    marginTop: 15,
    width: "100%",
  },
  bodyFooterMedium: {
    fontFamily: "RobotoMedium",
    fontSize: 8,
    marginTop: 10,
    textAlign: "justify",
  },
  bodyFooterCtn: {
    width: "80%",
    marginLeft: "10%",
    position: "absolute",
    bottom: 15,
    left: 0,
    right: 0,
    objectPosition: "bottom",
  },
  bodyFooterLight: {
    fontFamily: "RobotoLight",
    fontSize: 6,
    marginTop: 5,
  },
  bodyFooterMapped: {
    fontSize: 8,
    marginTop: 4,
  },
  bodyFooterMappedItalic: {
    fontSize: 8,
    marginTop: 4,
    fontFamily: "RobotoItalic",
  },
  bodyFooterMappedTitle: {
    fontSize: 8,
    marginTop: 4,
    fontFamily: "RobotoBold",
  },
  containerTitleSimu: {
    position: "relative",
    marginTop: 20,
    marginRight: "auto",
    marginLeft: "auto",
    width: "75%",
    maxHeight: 50,
    overflow: "hidden",
  },
  ctnChart: {
    position: "absolute",
    bottom: 220,
    width: 170,
    height: 180,
    left: "35%",
  },
  ctnEmol: {
    width: 150,
    height: 20,
    fontFamily: "RobotoRegular",
    textAlign: "left",
    fontSize: 10,
    paddingLeft: 5,
  },
  ctnResultatsNb: {
    width: "60%",
    height: 25,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingTop: 5,
    textAlign: "right",
  },
  ctnResultatsNb2: {
    width: 75,
    fontFamily: "RobotoBold",
    fontSize: 12,
    textAlign: "right",
  },
  ctnResultatsNb3: {
    width: "60%",
    height: 20,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingTop: 1,
    textAlign: "right",
  },
  ctnResultatsNbTop: {
    width: "60%",
    height: 25,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingTop: 5,
    borderBottom: "1px solid grey",
    textAlign: "right",
  },
  ctnResultatsTxt: {
    width: "100%",
    flex: 1,
    flexDirection: "row",
    justifyItems: "right",
    marginBottom: 25,
  },
  ctnResultatsTxt2: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignContent: "space-between",
    justifyContent: "space-between",
  },
  debours: {
    width: 100,
    height: 25,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingLeft: 5,
    paddingTop: 5,
    backgroundColor: "rgba(214,127,98,0.71)",
    borderTopLeftRadius: 2,
    borderBottom: "1px solid grey",
  },
  dmtgResults: {
    fontWeight: "normal",
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  donataireBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: "5px",
    marginBottom: "15px",
    border: "1px solid #71ADD9",
    borderRadius: 3,
    width: "80%",
    marginLeft: "10%",
    fontFamily: "RobotoBold",
    height: "auto",
    minHeight: "150px",
  },
  donataireTitle: {
    height: "100%",
    width: "auto",
    fontSize: 13,
    color: "white",
    backgroundColor: "#71ADD9",
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 40,
  },
  donateurBlock: {
    flex: 1,
    border: "1px solid rgb(157 114 169)",
    borderRadius: 3,
    margin: 10,
    maxWidth: "45%",
  },
  donateurResults: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  donateurTitle: {
    width: "100%",
    textAlign: "center",
    color: "white",
    backgroundColor: "rgb(157 114 169)",
    fontSize: 12,
    padding: 10,
  },
  emoluments: {
    width: "70%",
    height: 25,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingLeft: 5,
    paddingTop: 5,
    backgroundColor: "rgba(164,148,128,0.8)",
    borderTopLeftRadius: 2,
    borderBottom: "1px solid grey",
  },
  headerAdress: {
    fontSize: 10,
    fontFamily: "RobotoRegular",
  },
  headerDate: {
    marginTop: 10,
    fontSize: 9.5,
    fontFamily: "RobotoRegular",
  },
  headerName: {
    fontSize: 11,
    fontFamily: "RobotoBold",
    wordBreak: "keep-all",
  },
  headerRight: {
    marginLeft: "50%",
    marginTop: 5,
    marginRight: 40,
    fontSize: 12,
    textAlign: "right",
    lineHeight: 1.5,
    wordBreak: "keep-all",
  },
  heritierBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 5,
    marginBottom: 15,
    border: "1px solid #71ADD9",
    borderRadius: 3,
    width: "80%",
    marginLeft: "10%",
    fontFamily: "RobotoBold",
    height: "auto",
  },
  heritierBlock2: {
    flex: 1,
    margin: 10,
    maxWidth: "100%",
  },
  heritierTitle: {
    height: "100%",
    width: "auto",
    fontSize: 13,
    color: "white",
    backgroundColor: "#71ADD9",
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 20,
  },
  page: {
    paddingTop: 20,
    paddingBottom: 50,
  },
  rappel: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    columnGap: 50,
    width: "50%",
    height: 150,
    marginLeft: "10%",
    marginRight: "10%",
    position: "absolute",
    bottom: 60,
  },
  rappelBlockDonataire: {
    marginTop: 5,
    marginBottom: 10,
  },
  rappelDMTG: {
    position: "relative",
    width: "80%",
    marginLeft: "10%",
    // marginTop: 175,
    marginTop: 30,
    marginBottom: 50,
    paddingBottom: 150,
  },
  rappelDonateurTitle: {
    fontSize: 10,
    marginTop: 5,
    fontWeight: "bold",
    color: "rgb(157 114 169)",
  },
  rappelDonataireTitle: {
    fontSize: 9,
    fontWeight: "bold",
    color: "#71ADD9",
  },
  remarque: {
    border: "1px solid grey",
    borderRadius: 3,
    width: "80%",
    marginLeft: "10%",
    fontFamily: "RobotoRegular",
    fontSize: 10,
    marginTop: 15,
    lineHeight: 2,
    maxHeight: 40,
    overflow: "hidden",
  },
  remarqueText: {
    marginTop: 8,
    marginLeft: 5,
  },
  resultatsNb: {
    textAlign: "right",
    position: "relative",
    right: 7,
  },
  resultatsNb2: {
    fontSize: 12,
    textAlign: "right",
    position: "relative",
    right: 7,
  },
  resultsBlock: {
    marginTop: 5,
    marginBottom: 10,
  },
  resultsTitle: {
    fontSize: 14,
    paddingLeft: "10%",
    width: "100%",
    marginBottom: 10,
    marginTop: 15,
  },
  resultatsTxt: {
    width: 100,
    height: 25,
    fontFamily: "RobotoRegular",
    textAlign: "left",
    fontSize: 10,
    paddingLeft: 30,
    paddingTop: 5,
  },
  resultatsTxt2: {
    width: 170,
    height: 20,
    fontFamily: "RobotoRegular",
    textAlign: "left",
    fontSize: 10,
    paddingLeft: 30,
    paddingTop: 1,
  },
  resultatsTxt3: {
    width: 100,
    height: 20,
    fontFamily: "RobotoLight",
    textAlign: "left",
    fontSize: 10,
    paddingLeft: 30,
    paddingTop: 1,
  },
  superEmol: {
    height: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: 5,
    marginBottom: 5,
  },
  taxes: {
    width: 100,
    height: 25,
    fontFamily: "RobotoBold",
    fontSize: 12,
    paddingLeft: 5,
    paddingTop: 5,
    backgroundColor: "rgba(71,143,154,0.73)",
    borderTopLeftRadius: 2,
    borderBottom: "1px solid grey",
  },
  titleSimu: {
    fontSize: 13,
    textAlign: "center",
    fontFamily: "RobotoBold",
    wordBreak: "keep-all",
  },
  ttc: {
    border: "1px solid grey",
    borderRadius: 3,
    width: "80%",
    marginLeft: "10%",
    fontFamily: "RobotoBold",
    fontSize: 14,
    marginTop: 10,
    height: 45,
  },
  ttcInternCtn: {
    flex: 1,
    flexDirection: "row",
    marginTop: 15,
  },
  ttcLeft: {
    paddingLeft: 5,
    width: "70%",
  },
  ttcRight: {
    paddingRight: 5,
    width: "30%",
    textAlign: "center",
  },
});

const GenerateMiniPdfFile = ({ simulateurObject }) => {
  let remarque = localStorage.getItem("remarque");

  let currUser = JSON.parse(localStorage.getItem("user"));

  let requestSimu = JSON.parse(localStorage.getItem("requestSimu"));

  let nameSimu = localStorage.getItem("nameSimu");

  let result;
  if (simulateurObject["results"]["custom"].length > 0) {
    result = simulateurObject["results"]["custom"];
  } else {
    result = simulateurObject["results"]["output"];
  }

  return (
    // <PDFViewer style={{width: "98vw", height: "98vh"}}>
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerRight}>
          <Text style={styles.headerName}>{currUser.societe}</Text>
          <Text style={styles.headerAdress}>{currUser.societe_address}</Text>

          <Text style={styles.headerAdress}>{currUser.societe_zip + " " + currUser.societe_town}</Text>

          <Text style={styles.headerDate}>Dossier suivi par : {currUser.firstname + " " + currUser.lastname}</Text>

          <View style={styles.headerDate}>
            <Text>Le : {currentDate}</Text>
          </View>
        </View>
        <View style={styles.containerTitleSimu}>
          <Text style={styles.titleSimu}>{nameSimu}</Text>
        </View>
        <View style={styles.remarque}>
          <Text style={styles.remarqueText}>Remarques : {remarque}</Text>
        </View>

        <View style={styles.ttc}>
          <View style={styles.ttcInternCtn}>
            <Text style={styles.ttcLeft}>Estimation du montant total de vos frais :</Text>

            <Text style={styles.ttcRight}>
              {(() => {
                for (let i = 0; i < result.length; i++) {
                  if (result[i].key === "Provision_total") {
                    const numericValue = parseFloat(result[i].value);
                    const roundedValue = Math.ceil(numericValue);
                    const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                    return (formattedValue + " €").toString();
                  }
                }
                return "";
              })()}
            </Text>
          </View>
        </View>

        <View style={styles.bodyCtnRow}>
          <View style={styles.blockLeft}>
            <View style={styles.blockEmoluments}>
              <View style={styles.ctnResultatsTxt}>
                {requestSimu.simulateurid === 32 || requestSimu.simulateurid === 83 ? (
                  <Text style={styles.emoluments}>Honoraires HT</Text>
                ) : (
                  <Text style={styles.emoluments}>Total Emoluments</Text>
                )}
                <View style={styles.ctnResultatsNbTop}>
                  <Text style={styles.resultatsNb}>
                    {(() => {
                      for (let i = 0; i < result.length; i++) {
                        if (result[i].key === "Emol_total") {
                          const numericValue = parseFloat(result[i].value);
                          const roundedValue = Math.ceil(numericValue);
                          const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                          return (formattedValue + " € HT").toString();
                        }
                      }
                      return "";
                    })()}
                  </Text>
                </View>
              </View>

              <View style={styles.ctnResultatsTxt2}>
                <View style={styles.superEmol}>
                  <Text style={styles.ctnEmol}>Emoluments d'acte</Text>
                  <View style={styles.ctnResultatsNb2}>
                    <Text style={styles.resultatsNb2}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "emoluments_actes_total") {
                            const numericValue = parseFloat(result[i].value);
                            const roundedValue = Math.ceil(numericValue);
                            const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                            return (formattedValue + " € HT").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>

                <View style={styles.superEmol}>
                  <Text style={styles.ctnEmol}>Emoluments de formalités</Text>

                  <View style={styles.ctnResultatsNb2}>
                    <Text style={styles.resultatsNb2}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          //console.log(result[i].key)
                          if (result[i].key === "Emol_Form_Total") {
                            //console.log(result[i].value)
                            if (result[i].value !== "écrêtés") {
                              const numericValue = parseFloat(result[i].value);
                              const roundedValue = Math.ceil(numericValue);
                              const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                              return (formattedValue + " € HT").toString();
                            } else {
                              return result[i].value;
                            }
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.blockRight}>
            {simulateurObject?.data?.DMTG?.length > 0 ? (
              <View style={styles.blockFiscalite2}>
                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.taxes}>Total Fiscalité</Text>
                  <View style={styles.ctnResultatsNbTop}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        let tva = 0;
                        let tresor = 0;
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "TVA_total") tva = parseFloat(result[i].value);
                          else if (result[i].key === "Tresor_total") {
                            tresor = parseFloat(result[i].value);
                          }
                        }
                        const totalValue = tva + tresor;
                        const roundedTotal = Math.ceil(totalValue);
                        const formattedValue = roundedTotal.toLocaleString("en-EN").replace(/,/g, " ") + " €";
                        return formattedValue;
                      })()}
                    </Text>
                  </View>
                </View>

                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.resultatsTxt}>Trésor</Text>
                  <View style={styles.ctnResultatsNb}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "Tresor_total") {
                            const numericValue = parseFloat(result[i].value);
                            const roundedValue = Math.ceil(numericValue);
                            const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>
                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.resultatsTxt3}>Dont DMTG</Text>
                  <View style={styles.ctnResultatsNb3}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "DMTG") {
                            const formattedValue = parseInt(result[i].value).toLocaleString("en-EN").replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>

                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.resultatsTxt2}>TVA</Text>
                  <View style={styles.ctnResultatsNb3}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "TVA_total") {
                            const numericValue = parseFloat(result[i].value);
                            const roundedValue = Math.ceil(numericValue);
                            const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>
              </View>
            ) : (
              <View style={styles.blockFiscalite}>
                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.taxes}>Total Fiscalité</Text>
                  <View style={styles.ctnResultatsNbTop}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        let tva = 0;
                        let tresor = 0;
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "TVA_total") {
                            tva = parseFloat(result[i].value);
                          } else if (result[i].key === "Tresor_total") {
                            tresor = parseFloat(result[i].value);
                          }
                        }
                        const totalValue = tva + tresor;
                        const roundedTotal = Math.ceil(totalValue);
                        const formattedValue = roundedTotal.toLocaleString("en-EN").replace(/,/g, " ") + " €";
                        return formattedValue;
                      })()}
                    </Text>
                  </View>
                </View>

                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.resultatsTxt}>Trésor</Text>
                  <View style={styles.ctnResultatsNb}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "Tresor_total") {
                            const numericValue = parseFloat(result[i].value);
                            const roundedValue = Math.ceil(numericValue);
                            const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>

                <View style={styles.ctnResultatsTxt}>
                  <Text style={styles.resultatsTxt2}>TVA sur émoluments</Text>

                  <View style={styles.ctnResultatsNb}>
                    <Text style={styles.resultatsNb}>
                      {(() => {
                        for (let i = 0; i < result.length; i++) {
                          if (result[i].key === "TVA_total") {
                            const numericValue = parseFloat(result[i].value);
                            const roundedValue = Math.ceil(numericValue);
                            const formattedValue = roundedValue.toLocaleString("en-EN").replace(/,/g, " ");
                            return (formattedValue + " €").toString();
                          }
                        }
                        return "";
                      })()}
                    </Text>
                  </View>
                </View>
              </View>
            )}

            <View style={styles.blockDebours}>
              <Text style={styles.debours}>Debours</Text>
              <View style={styles.ctnResultatsNb}>
                <Text style={styles.resultatsNb}>
                  {(() => {
                    for (let i = 0; i < result.length; i++) {
                      if (result[i].key === "Debours_total") {
                        const formattedValue = parseInt(result[i].value).toLocaleString("en-EN").replace(/,/g, " ");
                        return (formattedValue + " €").toString();
                      }
                    }
                    return "";
                  })()}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* MAP FOR INPUTS*/}

        <View style={styles.rappel}>
          <Text style={styles.bodyFooterMappedTitle}>Rappel de votre simulation :</Text>
          {requestSimu?.departementid !== 0 ? (
            <Text style={styles.bodyFooterMapped}>
              Département concerné :{" "}
              {requestSimu.departementid < 10 ? `0${requestSimu.departementid}` : requestSimu.departementid}
              {/*TODO Afficher le nom du département*/}
            </Text>
          ) : (
            <></>
          )}
          {Object.keys(requestSimu?.inputs).map((key) => {
            if (key === "archivage") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Archivage : Oui</Text>;
              } else if (requestSimu.inputs[key] === 0) {
                return <Text style={styles.bodyFooterMapped}>Archivage : Non</Text>;
              }
            } else if (key === "valeur_transmise") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur transmise :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nombre_page") {
              if (requestSimu.inputs[key] > 0) {
                return <Text style={styles.bodyFooterMapped}>Nombre de pages : {requestSimu.inputs[key]}</Text>;
              }
            } else if (key === "etat_civil") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Pièces d’état-civil demandées : Oui</Text>;
              }
            } else if (key === "comedec") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Recours à Comedec : Oui</Text>;
              }
            } else if (key === "nombre_comedec_2") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>Nombre de Comedec à 3,64€ : {requestSimu.inputs[key]}</Text>
                );
              }
            } else if (key === "nombre_comedec_1") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>Nombre de Comedec à 0,64€ : {requestSimu.inputs[key]}</Text>
                );
              }
            } else if (key === "nombre_copie_authentique") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre pages "copie authentique" : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "nombre_copie_libre") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>Nombre pages "copie libre" : {requestSimu.inputs[key]}</Text>
                );
              }
            } else if (key === "nombre_archivage") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre pages "archivage numérisé" : {requestSimu.inputs[key]}
                  </Text>
                );
              }
            } else if (key === "codicilles") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Codicille(s) déposé(s) conservé(s) à l’étude : Oui</Text>;
              }
            } else if (key === "nombre_codicilles") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>Nombre de codicilles déposés : {requestSimu.inputs[key]}</Text>
                );
              }
            } else if (key === "envoi_possession") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Envoi en possession : Oui</Text>;
              }
            } else if (key === "valeur_bien") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur bien immobilier :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_biens_declares") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens déclarés et/ou apportés :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "formalites_publication") {
              if (requestSimu.inputs[key] === 1) {
                return <Text style={styles.bodyFooterMapped}>Formalités de publication au SPF : Oui</Text>;
              }
            } else if (key === "valeur_biens_immo") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur bien immobilier :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nombre_enfants") {
              if (requestSimu.inputs[key] > 0) {
                return <Text style={styles.bodyFooterMapped}>Nombre enfants majeurs : {requestSimu.inputs[key]}</Text>;
              }
            } else if (key === "montant_partage") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant actif brut à partager :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_partage_brut") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant actif brut à partager :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "montant_partage_net") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Montant actif net partagé :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "valeur_biens_partages") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Valeur biens immobiliers partagés :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ") + " €"}
                  </Text>
                );
              }
            } else if (key === "nombre_part_partage") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre parts sociales faisant l’objet du partage partiel :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ")}
                  </Text>
                );
              }
            } else if (key === "nombre_parts_capital") {
              if (requestSimu.inputs[key] > 0) {
                return (
                  <Text style={styles.bodyFooterMapped}>
                    Nombre parts sociales composant le capital social :{" "}
                    {parseInt(requestSimu.inputs[key]).toLocaleString("en-EN").replace(/,/g, " ")}
                  </Text>
                );
              }
            }
            return <></>;
          })}
        </View>

        <View style={styles.bodyFooterCtn} fixed>
          <Text style={styles.bodyFooterMedium}>
            IMPORTANT : Les informations et les résultats présentés ci-dessus sont donnés à titre indicatif et ne
            peuvent en aucun cas constituer un document à caractère contractuel. Les frais indiqués doivent être
            adressés par virement au minimum 48h-72h à l'avance.
          </Text>
          <Text style={styles.bodyFooterLight}>Sources : Éditions Langloÿs - Le Barème Rapide® {year}</Text>
        </View>
      </Page>
    </Document>
  );
};
export default GenerateMiniPdfFile;
